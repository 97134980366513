import Image from 'antd/lib/image';
import { Helmet } from 'react-helmet'
import './galery.css'


let images = [
    'https://cdn.pixabay.com/photo/2013/03/02/17/38/free-fall-89349_1280.jpg',
    'https://cdn.pixabay.com/photo/2013/02/10/14/10/parachute-80088_1280.jpg',
    'https://cdn.pixabay.com/photo/2018/08/07/00/01/sky-3588757_1280.jpg',
    'https://cdn.pixabay.com/photo/2019/07/05/19/40/skydiving-4319229_1280.jpg',
    'https://cdn.pixabay.com/photo/2018/06/24/21/21/balaton-3495488_1280.jpg',
]
function Galery() {
  return (
    <>
    <Helmet>
        <title>Galéria - Skydive.hu</title>
        <meta name="description" content="documents" />
    </Helmet>
    <div className='galery-content'>
        <div className='galery-label'>
            <h1>Galéria</h1>
        </div>
        <Image.PreviewGroup style={{zIndex: '1'}}>
            <div style={{display: 'flex',  flexWrap: 'wrap', justifyContent: 'center', width: '100%'}}>
            {images.map(image => {
                return (
                    <div style={{margin: '10px'}}>
                        <Image
                        width={300}
                        height={200}
                        src={image}
                        />
                    </div>
                )
            })}
            </div>
        </Image.PreviewGroup>
        <a style={{padding: "20px", textAlign: "center", fontSize: "20px"}} href="https://www.szon.hu/galeria/a-legendas-ugrasra-emlekeztek-fotok-dodo-ferenc/1" target="_blank">https://www.szon.hu/galeria/a-legendas-ugrasra-emlekeztek-fotok-dodo-ferenc/1</a>
    </div>
    </>
  );
}

export default Galery;