import { onSnapshot, collection, addDoc, deleteDoc, doc } from 'firebase/firestore';
import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { Helmet } from 'react-helmet';

import db from "../../firebase";

import './documents.css'


function DocumentCollection() {
    const [documents, setDocuments] = useState([{ name: "Loading...", id: "initial" }]);
    const [docUrl, setDocUrl] = useState('')
    const [docName, setDocName] = useState('')
    const [categories, setCategories] = useState(["Aeromagazin", "Szabályzatok", "Beszámolók", "Biztosítás", "Regisztrációs adatlapok", "Rendeletek", "Tandem", "Egyéb"])
    const [category, setCategory] = useState();
    const [searchData, setSearchData] = useState("");
    const { loggedIn } = useContext(UserContext);

    const results = !searchData
    ? documents
    : documents.filter(document =>
        document.name.toLowerCase().includes(searchData.toLocaleLowerCase())
    );

    useEffect(() => {
        const unsub = onSnapshot(collection(db, "documents"), (snapshot) => {
            setDocuments(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        })
        return unsub
    }, [])

    const handleNewDocument = async () => {

        const collectionRef = collection(db, "documents");
        let payload = {name: docName, value: docUrl, category}
        await addDoc(collectionRef, payload)
        setDocName('');
        setDocUrl('');
    }

    const handleDeleteDocument = async (id) => {
        const docRef = doc(db, "documents", id)
        await deleteDoc(docRef)
    }
    return (
        <>
            <Helmet>
                <title>Dokumentumok - Skydive.hu</title>
                <meta name="description" content="documents" />
            </Helmet>
            <div className="doc-content">
        {loggedIn ?
               <> 
            <p>Dokumentum neve:</p>
            <input 
                className="doc-upload-input"
                type="text"
                id="name"
                name="name"
                placeholder="például: Jegyzőkönyv"
                onChange={(e) => setDocName(e.target.value)}
                value={docName} />
            <p>Dokumentum Google Drive linkje:</p>
            <input 
                className="doc-upload-input"
                type="text"
                id="url"
                name="url"
                placeholder="például: https://docs.google.com/document/d/1SnInFXluJC1KNFGsLwchkAcq912rAfJogBST4U4o12Q"
                onChange={(e) => setDocUrl(e.target.value)}
                value={docUrl} />
            <p>Dokumentum kategóriája:</p>
            <div className="doc-categories">  
                {categories.map((cat, i) => {
                    return <button 
                        id={i} 
                        className={category === cat ? "doc-cat-btn-selected" : "doc-cat-btn" }
                        onClick={() => setCategory(cat)}>
                            {cat}
                        </button>
                })}
            </div>
            <button className="doc-upload-btn" onClick={handleNewDocument}>Új dokumentum feltöltése</button>
            </> : null
            }
            <h1>Letölthető dokumentumok</h1>
            <div className='doc-search'>
                <input
                 type="text" 
                 placeholder="Keresés..." 
                 value={searchData} 
                 onChange={event => setSearchData(event.target.value)}
                 className="search-input"   
                 />
            </div>
            {categories.map((cat, i) => {
               return (
                <ul>
                    {searchData ? null : <h1 className="doc-label">{cat}</h1>}
                    {results.map((doc) => {
                        if (doc.category === cat) {
                            return (
                            <li key={doc.id} className="doc-list-item">
                                <a 
                                className="docLink" 
                                href={doc.value} 
                                target="_blank" 
                                rel="noreferrer">
                                    {doc.name}
                                </a>
                                {loggedIn? <button className="doc-delete-btn" onClick={() => handleDeleteDocument(doc.id)}>Törlés</button> : null}
                            </li>
                        );
                        }
                    })}
                </ul>
                )
            })}
        </div>
        </>

  );
}




export default DocumentCollection;
