import React, { useContext } from "react";
import { withNamespaces } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { EventContext } from "../../context/event-context";
import Snowfall from "react-snowfall";

import logo from "../../assets/MESZ.png";
import xmas from "../../assets/xmas.jpg";

import "./home.css";

function Home({ t }) {
  let event = useContext(EventContext);

  return (
    <>
      <Helmet>
        <title>Magyar Ejtőernyős Szövetség - skydive.hu</title>
        <meta name="description" content="skydive" />
      </Helmet>

      <div className="home-container">
        <Snowfall
          speed={[0, 0.5]}
          radius={[0.5, 2]}
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
          }}
        />

        <div className="xmas_div">
          <img src={xmas} alt="xmas_card" />
        </div>
        <div className="home">
          <div className="circle-back"></div>
          {/* Logo */}
          <div className="circle_logo_container">
            <h1>Üdvözöljük</h1>
            <h1>a</h1>
            <img
              src={logo}
              alt="Magyar Ejtőernyős szövetség logó"
              className="mesz"
            />
            <h1>weboldalán!</h1>
          </div>

          {/* Clouds */}
          <div className="cloud-animation-container">
            <img
              alt="animated-cloud1"
              src="https://www.nicepng.com/png/full/1-14698_cumulus-cloud-png-clipart-clouds-png.png"
              style={{ width: "300px", opacity: 0.8 }}
            />
          </div>
          <div className="cloud-animation-container-2">
            <img
              alt="animated-cloud2"
              src="https://www.nicepng.com/png/full/1-14698_cumulus-cloud-png-clipart-clouds-png.png"
              style={{ width: "200px", opacity: 0.8 }}
            />
          </div>
          {/*           <div className="cloud-animation-container-3">
            <img
              alt="animated-cloud3"
              src="https://www.nicepng.com/png/full/1-14698_cumulus-cloud-png-clipart-clouds-png.png"
              style={{ width: "300px", opacity: 0.8 }}
            />
          </div>
          <div className="cloud-animation-container-4">
            <img
              alt="animated-cloud4"
              src="https://www.nicepng.com/png/full/1-14698_cumulus-cloud-png-clipart-clouds-png.png"
              style={{ width: "300px", opacity: 0.8 }}
            />
          </div> */}

          <div className="home_right-container">
            {/*             <div className="upcoming-events">
              <h1>Közelgő események</h1>
              {event.actualEvent.length > 0 ? (
                event.actualEvent.map((event, i) => {
                  return (
                    <NavLink to="/calendar" key={i} className="upcoming-event">
                      <span>
                        <strong>{event.start.date}</strong> -{" "}
                        <strong>{event.end.date}</strong>
                      </span>
                      <p to="/calendar" className="event-name">
                        {event.summary}
                      </p>
                    </NavLink>
                  );
                })
              ) : (
                <p>Nem várható a közeljövőben esemény</p>
              )}
            </div> */}
          </div>
          <a
            href="#about"
            className="ca3-scroll-down-link ca3-scroll-down-arrow"
            data-ca3_iconfont="ETmodules"
            data-ca3_icon=""
          >
            {" "}
          </a>
        </div>

        {/* About Us Section */}
        <div className="about-cont" id="about">
          <h1>Rólunk</h1>
          <div className="about-content">
            <img
              className="about-image"
              src="https://cdn.pixabay.com/photo/2019/07/05/19/40/skydiving-4319229_1280.jpg"
              alt="Rólunk"
            />
            <p className="intro">
              A Magyar Ejtőernyős Szövetség (MESZ) látja el a hazai
              ejtőernyősök, úgymint a célbaugrók, a formaugrók, a swooperek
              (kupola kezelők), a szárnyasruhások, a szélcsatorna sportolók és
              amatőrök/hobbi ugrók hivatalos képviseletét idehaza és külföldön.
              Ennek keretében versenyzői akkreditációk, licenszek beszerzése és
              nyilvántartása, magyar nemzeti versenyek szervezése és
              lebonyolítása, továbbá lehetőségeink függvényében azok anyagi,
              szakmai támogatása.A MESZ az ejtőernyős képzés, átképzés terén
              nagy tapasztalattal rendelkező, nemzetközileg is elismert
              ejtőernyősök tömörítésével, azok tudására támaszkodva a szakmaiság
              legfőbb fóruma, mely elkötelezett híve a repülésbiztonságnak.A
              MESZ évente több tucat növendéket nevel a jövő ejtőernyős
              nemzedékének és tandem ugrások szervezésével is népszerűsíti
              sportágunkat a kívülállók számára.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default withNamespaces()(Home);
